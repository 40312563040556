import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  EventEmitter,
  HostListener,
  input,
  Input,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import { InputComponent } from '../../forms/input/input.component';
import { MemberInfoComponent } from '../member-info/member-info.component';
import { IMember } from '../../../core/interfaces/action-plans.interface';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-members-manager',
  standalone: true,
  imports: [CommonModule, InputComponent, MemberInfoComponent, FormsModule],
  templateUrl: './members-manager.component.html',
  styleUrl: './members-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersManagerComponent {
  @Input() position: 'top' | 'mid' | 'bottom' = 'mid';
  @Input() onlyMembers: boolean = false;
  @Input() onlyUsers: boolean = false;

  @Output() onInsert = new EventEmitter<IMember>();
  @Output() onRemove = new EventEmitter<IMember>();

  textFilter = signal<string>('');

  allUsers = input<IMember[]>([]);

  members = computed(() =>
    this.allUsers().filter(
      (user) =>
        (!!user.isMember || (!!user.isResponsible && this.onlyMembers)) &&
        this.filter(user)
    )
  );

  users = computed(() =>
    this.allUsers().filter(
      (user) => !user.isMember && !user.isResponsible && this.filter(user)
    )
  );

  showModal = signal(false);

  @ViewChild('targetMembers') targetDivRef!: ElementRef;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    if (
      this.targetDivRef &&
      !this.targetDivRef.nativeElement.contains(event.target) &&
      !!this.showModal()
    ) {      
      this.showModal.set(false);
    }
  }

  filter = (user: IMember) => {
    return user.name.toLowerCase().includes(this.textFilter().toLowerCase());
  };

  handleModal(): void {
    setTimeout(() => {
      this.showModal.set(!this.showModal());
    }, 100);
  }
}
