<div class="row align-center justify-space-between py-10 box-input">
  <div class="px-15">
    <label class="checkbox-container">
      <div class="row align-center">
        <input
          type="checkbox"
          name="checkbox"
          [checked]="!!task()?.finished"
          (click)="changeFinished()"
          [disabled]="!!editMode() || !hasPermissionEdit"
        />
        <span class="checkmark text-center">
          <img src="/assets/icons/check-white.svg" alt="check" />
        </span>
        <span class="checkbox-label"></span>
      </div>
    </label>
  </div>
  <div class="flex-1">
    @if (!editMode()) {
    <p
      class="font-size-14 text"
      (click)="changeMode()"
      [ngClass]="{ 'marked-text': task()?.finished }"
    >
      {{ task()?.description }}
    </p>
    }@else {
    <app-textarea
      class="input-text"
      rows="2"
      [(ngModel)]="task()!.description"
      [openFocus]="true"
      (keydown.enter)="changeDescription()"
      (blur)="blurField()"
    >
    </app-textarea>
    }
  </div>
  <div class="px-10 row column-gap-5">
    <app-member-info
      [member]="task()?.member!"
      [allowInfo]="true"
      position="left"
    ></app-member-info>
    <button *allowed="['goals-edit']" type="button" class="btn-icon">
      <img
        width="14px"
        src="/assets/icons/garbage-primary.svg"
        alt="lixeira"
        (click)="onRemove.emit(task())"
      />
    </button>
  </div>
</div>
