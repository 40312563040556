import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  input,
  OnInit,
  Output
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MembersManagerComponent } from '../../components/members-manager/members-manager.component';
import { InputComponent } from '../input/input.component';
import { TextareaComponent } from '../textarea/textarea.component';
import {
  IMember,
  ITaskActionPlan
} from '../../../core/interfaces/action-plans.interface';
import { MemberInfoComponent } from '../../components/member-info/member-info.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-card-add-action-plan',
  standalone: true,
  templateUrl: './card-add-action-plan.component.html',
  styleUrl: './card-add-action-plan.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    InputComponent,
    TextareaComponent,
    MembersManagerComponent,
    FormsModule,
    MemberInfoComponent
  ]
})
export class CardAddActionPlanComponent implements OnInit {
  users = input<IMember[]>([]);

  @Output() onInsert = new EventEmitter<ITaskActionPlan>();
  @Output() onClose = new EventEmitter();

  description: string = '';
  member: IMember | null = null;

  openFocus = true;

  constructor(private router: Router) {
    if (this.router.url.includes('/plataforma/metas/formulario')) {
      this.openFocus = false;
    }
  }

  ngOnInit(): void {
    if (this.router.url.includes('/plataforma/metas/formulario')) {
      this.openFocus = false;
    }
  }

  insertTask(): void {
    this.onInsert.emit({
      description: this.description,
      member: this.member!,
      finished: false
    });
  }
}
