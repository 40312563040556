<div class="position-relative d-flex">
  <button type="button" class="btn-radio" (click)="handleModal()">
    <img width="16px" src="/assets/icons/plus-full.svg" alt="" />
  </button>

  @if (showModal()) {
  <div #targetMembers class="box-ctn {{ position }}">
    <div class="d-flex justify-center position-relative">
      <span class="font-semi-bold">Gerenciar {{onlyUsers ? 'responsável' : 'membros'}}</span>
      <button type="button" class="btn-icon close" (click)="handleModal()">
        <img width="12px" src="/assets/icons/close-dialog.svg" alt="" />
      </button>
    </div>
    <app-input
      placeholder="Pesquisar"
      [(ngModel)]="textFilter"
    ></app-input>
    <div class="members-list">
      @if (!onlyUsers) {
      <div class="pr-5">
        <span class="font-semi-bold">Membros</span>
        <div class="d-flex direction-column row-gap-5 pt-5">
          @for (item of members(); track item.id) {
          <div
            class="d-flex align-center justify-space-between nowrap"
            [ngClass]="{ pointer: onlyMembers }"
            (click)="onlyMembers && onInsert.emit(item)"
          >
            <app-member-info [member]="item"></app-member-info>
            <span class="flex-1 pl-5">{{ item.name }}</span>
            @if (!onlyMembers) {
            <button
              type="button"
              class="btn-icon"
              (click)="onRemove.emit(item)"
            >
              <img width="10px" src="/assets/icons/close-dialog.svg" alt="" />
            </button>
            }
          </div>
          } @empty {
          <span class="font-size-14 font-regular row"
            >Nenhum membro adicionado</span
          >
          }
        </div>
      </div>
      } @if (!onlyMembers) {
      <div class="py-10 pr-5">
        <span class="font-semi-bold">Usuários</span>
        @for (item of users(); track item.id) {
        <div
          class="d-flex direction-column row-gap-5 pt-5"
          (click)="onInsert.emit(item)"
        >
          <div class="d-flex align-center justify-space-between nowrap field">
            <app-member-info [member]="item"></app-member-info>
            <span class="flex-1 pl-5">{{ item.name }}</span>
          </div>
        </div>
        } @empty {
        <span class="font-size-14 font-regular row"
          >Nenhum usuário disponível</span
        >
        }
      </div>
      }
    </div>
  </div>
  }
</div>
