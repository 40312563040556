import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  computed,
  input,
  signal
} from '@angular/core';
import { IMember } from '../../../core/interfaces/action-plans.interface';
import { AllowedDirective } from '../../../core/directives/allowed.directive';

@Component({
  selector: 'app-member-info',
  standalone: true,
  imports: [CommonModule, AllowedDirective],
  templateUrl: './member-info.component.html',
  styleUrl: './member-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberInfoComponent implements OnInit {
  @Input() allowInfo: boolean = false;
  @Input() allowRemove: boolean = false;
  @Input() position: 'left' | 'right' = 'right';

  member = input.required<IMember | null>();

  @Output() onRemove = new EventEmitter();

  initials = computed(() => this.getInitials(this.member()));

  showInfo = signal(false);

  @ViewChild('targetDiv') targetDivRef!: ElementRef;

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    if (
      this.targetDivRef &&
      !this.targetDivRef.nativeElement.contains(event.target)
    ) {
      this.showInfo.set(false);
    }
  }

  ngOnInit(): void {
    if (!this.member()) {
      this.allowInfo = false;
    }
  }

  getInitials(member: IMember | null): string {
    let name = member?.name || '';

    const parts = name.split(' ');
    const firstNameInitial = parts[0][0] ?? '';
    const lastNameInitial = parts.length > 1 ? parts[1][0] : '';
    return `${firstNameInitial}${lastNameInitial}`?.toUpperCase();
  }

  openInfo(): void {
    setTimeout(() => {
      this.showInfo.set(true);
    }, 0);
  }

  removeMember(): void {
    this.onRemove.emit(this.member());
    this.showInfo.set(false);
  }
}
