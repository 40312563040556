<div class="position-relative">
  <div
    class="radio-ctn"
    [ngClass]="{ pointer: allowInfo, 'bg-void': !member() }"
    (click)="allowInfo && openInfo()"
  >
    <span class="initials">
      {{ initials() }}
    </span>
  </div>

  @if (showInfo()) {
  <div #targetDiv class="member-info {{ position }}">
    <div class="d-flex align-center column-gap-10">
      <div class="radio-ctn expand">
        <span class="initials">
          {{ initials() }}
        </span>
      </div>
      <span class="font-semi-bold">{{ member()?.name }}</span>
    </div>
    @if (allowRemove) {
    <ng-container *allowed="['goals-edit']">
      <div class="divisor"></div>
      <div>
        <a class="font-link font-semi-bold pointer" (click)="removeMember()"
          >Remover membro</a
        >
      </div>
    </ng-container>

    }
  </div>
  }
</div>
