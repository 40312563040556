<section class="position-relative">
  <div>
    <p class="font-semi-bold mb-15">Plano de ação</p>
  </div>
  <div class="mb-15 row align-center">
    <mat-progress-bar [value]="percentual()" mode="determinate" />
    <span class="pl-20 font-semi-bold"
      >{{ tasksFinished() }}/{{ tasks().length }}</span
    >
  </div>
  @for (item of tasks(); track item.id) {
  <app-checkbox-action-plans
    [hasPermissionEdit]="hasPermissionEdit"
    [task]="item"
    (onChange)="changeTask($event)"
    (onRemove)="removeTask($event)"
  ></app-checkbox-action-plans>
  } @if (!createMode()) {
  <div *allowed="['goals-edit']">
    <button
      type="button"
      (click)="openCreateMode()"
      class="btn-secondary px-20 mt-20"
    >
      Adicionar tarefa
    </button>
  </div>
  } @else {
  <app-card-add-action-plan
    [users]="users()"
    (onInsert)="insertTask($event)"
    (onClose)="createMode.set(false)"
  ></app-card-add-action-plan>
  }
</section>
