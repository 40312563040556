import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  Input,
  input,
  Output,
  signal
} from '@angular/core';
import { IMember } from '../../../core/interfaces/action-plans.interface';
import { GoalsService } from '../../../core/services/goals.service';
import { MemberInfoComponent } from '../member-info/member-info.component';
import { MembersManagerComponent } from '../members-manager/members-manager.component';
import { AllowedDirective } from '../../../core/directives/allowed.directive';

@Component({
  selector: 'app-members-section',
  standalone: true,
  imports: [
    CommonModule,
    MemberInfoComponent,
    MembersManagerComponent,
    AllowedDirective
  ],
  templateUrl: './members-section.component.html',
  styleUrl: './members-section.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MembersSectionComponent {
  @Input() mode: 'form' | 'details' = 'form';
  @Input() goalId: string = '';

  users = input<IMember[]>([]);
  responsible = input<IMember | null>(null);

  members = signal<IMember[]>([]);

  @Output() onChange = new EventEmitter<IMember[]>();
  @Output() onChangeResponsible = new EventEmitter<IMember | null>();

  constructor(private goalsService: GoalsService) {
    effect(
      () => {
        this.members.set(this.users().filter((member) => !!member.isMember));
      },
      { allowSignalWrites: true }
    );
  }

  insertMember(member: IMember): void {
    if (this.mode === 'details') {
      this.goalsService
        .insertMember(this.goalId, member)
        .subscribe((response) => {
          this.handleInsertedMember(member);
        });
    } else {
      this.handleInsertedMember(member);
    }
  }

  private handleInsertedMember(member: IMember) {
    this.users().find((u) => u.id === member.id)!.isMember = true;
    this.members.update((members) => [...members, member]);
    this.onChange.emit(this.users().filter((u) => u.isMember));
  }

  removeMember(member: IMember): void {
    if (this.mode === 'details') {
      this.goalsService
        .removeMember(this.goalId, member)
        .subscribe((response) => {
          this.handleRemovedMember(member);
        });
    } else {
      this.handleRemovedMember(member);
    }
  }

  private handleRemovedMember(member: IMember) {
    this.users().find((u) => u.id === member.id)!.isMember = false;
    this.members.update((members) => members.filter((m) => m.id !== member.id));
    this.onChange.emit(this.users().filter((u) => u.isMember));
  }

  insertResponsible(member: IMember): void {
    this.users().find((u) => u.id === member.id)!.isMember = true;
    this.onChangeResponsible.emit(member);
  }

  removeResponsible(member: IMember): void {
    this.users().find((u) => u.id === member.id)!.isMember = false;
    this.onChangeResponsible.emit(null);
  }
}
