import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Input,
  input,
  OnDestroy,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { CardAddActionPlanComponent } from '../../forms/card-add-action-plan/card-add-action-plan.component';
import { CheckboxActionPlansComponent } from '../../forms/checkbox-action-plans/checkbox-action-plans.component';
import {
  IMember,
  ITaskActionPlan
} from '../../../core/interfaces/action-plans.interface';
import { GoalsService } from '../../../core/services/goals.service';
import { Subscription } from 'rxjs';
import { AllowedDirective } from '../../../core/directives/allowed.directive';
import { AuthService } from '../../../core/services/security/auth.service';

@Component({
  selector: 'app-task-action-plans',
  standalone: true,
  templateUrl: './task-action-plans.component.html',
  styleUrl: './task-action-plans.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    CheckboxActionPlansComponent,
    MatProgressBarModule,
    CardAddActionPlanComponent,
    AllowedDirective
  ]
})
export class TaskActionPlansComponent implements OnInit, OnDestroy {
  @Input() goalId: string = '';
  @Input() mode: 'form' | 'details' = 'form';

  @Output() onChange = new EventEmitter<ITaskActionPlan[]>();

  tasks = input<ITaskActionPlan[]>([]);
  users = input<IMember[]>([]);

  tasksFinished = computed(
    () => this.tasks()?.filter((task) => task.finished).length
  );
  percentual = computed(
    () => (this.tasksFinished() / this.tasks().length) * 100
  );

  createMode = signal(false);

  hasPermissionEdit = false;

  subscription!: Subscription;

  constructor(
    private goalsService: GoalsService,
    private authService: AuthService
  ) {
    this.hasPermissionEdit = this.authService.hasPermission('goals-edit');
  }

  ngOnInit(): void {
    if (!this.tasks().length) {
      this.openCreateMode(false);
    }

    if (this.mode === 'form') {
      this.subscription = this.goalsService.changeMembersEvent.subscribe(() => {
        this.onChange.emit(
          this.tasks().filter(
            (t) =>
              (!!t.finished && t.id) ||
              this.users().some((u) => u.id === t.memberId && u.isMember)
          )
        );
      });
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  openCreateMode(allowScroll: boolean = true): void {
    this.createMode.set(true);

    if (allowScroll) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        });
      }, 0);
    }
  }

  changeTask(task: ITaskActionPlan): void {
    if (this.mode === 'details') {
      this.goalsService
        .updateActionPlan(this.goalId, task)
        .subscribe((response) => {
          this.onChange.emit([...this.tasks()]);
        });
    } else {
      this.onChange.emit([...this.tasks()]);
    }
  }

  insertTask(task: ITaskActionPlan): void {
    task.memberId = task?.member?.id ?? (null as any);

    if (this.mode === 'details') {
      this.goalsService
        .createActionPlan(this.goalId, task)
        .subscribe((response) => {
          this.onChange.emit([...response]);
          this.createMode.set(false);
        });
    } else {
      this.onChange.emit([...this.tasks(), task]);
      this.createMode.set(false);
    }
  }

  removeTask(task: ITaskActionPlan): void {
    if (this.mode === 'details') {
      this.goalsService
        .deleteActionPlan(this.goalId, task.id!)
        .subscribe(() => {
          this.onChange.emit(this.tasks().filter((t) => t !== task));
        });
    } else {
      this.onChange.emit(this.tasks().filter((t) => t !== task));
    }
  }
}
