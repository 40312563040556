<div class="task-container mt-15">
  <div class="row justify-flex-end pb-10">
    <img (click)="onClose.emit()" src="assets\icons\close-icon.svg" alt="" />
  </div>
  <div>
    <app-textarea
      placeholder="Descreva o plano de ação"
      [(ngModel)]="description"
      [openFocus]="openFocus"
    ></app-textarea>
  </div>
  <div class="row align-center pt-15">
    <div class="px-5">
      <img src="assets\icons\members-icon.svg" alt="membro" />
    </div>
    <div class="px-5">
      <p class="font-semi-bold font-size-14">Membro</p>
    </div>
    <div class="px-5">
      @if (!!member) {
      <app-member-info
        [member]="member"
        [allowInfo]="true"
        [allowRemove]="true"
        (onRemove)="member = null"
      ></app-member-info>
      } @else {
      <app-members-manager
        position="top"
        [allUsers]="users()"
        [onlyMembers]="true"
        (onInsert)="member = $event"
      ></app-members-manager>
      }
    </div>
  </div>
  <div class="pt-15 row justify-center">
    <button
      type="button"
      [disabled]="!description || !description.trim() || !member"
      class="btn-primary"
      (click)="insertTask()"
    >
      Salvar
    </button>
  </div>
</div>
