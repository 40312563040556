import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  input,
  Output,
  signal
} from '@angular/core';
import { TextareaComponent } from '../textarea/textarea.component';
import { MemberInfoComponent } from '../../components/member-info/member-info.component';
import { ITaskActionPlan } from '../../../core/interfaces/action-plans.interface';
import { AllowedDirective } from '../../../core/directives/allowed.directive';

@Component({
  selector: 'app-checkbox-action-plans',
  standalone: true,
  templateUrl: './checkbox-action-plans.component.html',
  styleUrl: './checkbox-action-plans.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FormsModule,
    TextareaComponent,
    MemberInfoComponent,
    AllowedDirective
  ]
})
export class CheckboxActionPlansComponent {
  @Input() hasPermissionEdit = false;

  task = input<ITaskActionPlan>();

  editMode = signal(false);
  rawText = '';

  @Output() onChange = new EventEmitter<ITaskActionPlan>();
  @Output() onRemove = new EventEmitter<ITaskActionPlan>();

  changeMode(): void {
    if (this.hasPermissionEdit) {
      !this.task()?.finished && this.editMode.set(true);
      this.rawText = this.task()?.description || '';
    }
  }

  changeFinished(): void {
    this.task()!.finished = !this.task()?.finished;
    this.onChange.emit(this.task());
  }

  changeDescription(): void {
    this.editMode.set(false);

    if (
      !this.task()?.description.trim() ||
      this.rawText === this.task()?.description
    ) {
      this.task()!.description = this.rawText;
      return;
    }

    this.onChange.emit(this.task());
  }

  blurField(): void {
    setTimeout(() => {
      this.changeDescription();
    }, 200);
  }
}
