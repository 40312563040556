<div class="column row-gap-20 font-size-14 font-semi-bold members-ctn">
  <div class="row column-gap-20 align-center">
    <span [ngClass]="{ 'font-size-16': mode === 'form' }"
      ><img width="12px" src="assets\icons\user.svg" alt="membro" /> Responsável</span
    >
    @if (!!responsible() || mode === 'details') {
    <app-member-info
      [member]="responsible()"
      [allowInfo]="true"
      [allowRemove]="mode === 'form'"
      (onRemove)="removeResponsible($event)"
    ></app-member-info>
    } @else {
    <app-members-manager
      position="bottom"
      [allUsers]="users()"
      [onlyUsers]="true"
      (onInsert)="insertResponsible($event)"
      (onRemove)="removeResponsible($event)"
    ></app-members-manager>
    }
  </div>
  <div class="row column-gap-15 align-center">
    <span class="d-flex" [ngClass]="{ 'font-size-16': mode === 'form' }"
      ><img width="20px" src="assets\icons\members-icon.svg" alt="membro" /> Membros</span
    >
    @for (item of members(); track $index) {
    <app-member-info
      [member]="item"
      [allowInfo]="true"
      [allowRemove]="true"
      (onRemove)="removeMember($event)"
    ></app-member-info>
    }
    <ng-container *allowed="['goals-edit']">
      <app-members-manager
        position="bottom"
        [allUsers]="users()"
        (onInsert)="insertMember($event)"
        (onRemove)="removeMember($event)"
      ></app-members-manager>
    </ng-container>
  </div>
</div>
